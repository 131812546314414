
import Vue from 'vue'
import Component from 'vue-class-component'

import * as analytics from '../helpers/analytics'

@Component({
  name: 'Footer'
})
export default class extends Vue {
  get icepanel () {
    return [
      {
        link: {
          name: 'features'
        },
        text: 'Features'
      },
      {
        link: {
          name: 'learn'
        },
        text: 'Learn'
      },
      {
        link: {
          name: 'pricing'
        },
        text: 'Pricing'
      },
      {
        link: {
          name: 'c4-model'
        },
        text: 'C4 Model'
      },
      {
        link: {
          name: 'roadmap'
        },
        text: 'Roadmap'
      },
      {
        link: {
          name: 'changelog'
        },
        text: 'Changelog'
      },
      {
        click: () => analytics.statusLink.track(this),
        href: 'https://status.icepanel.io',
        target: '_blank',
        text: 'Status'
      },
      {
        click: () => analytics.docsLink.track(this, {
          docsPosition: 'bottom',
          link: 'https://docs.icepanel.io'
        }),
        href: 'https://docs.icepanel.io',
        target: '_blank',
        text: 'Docs'
      },
      {
        link: {
          name: 'blog'
        },
        text: 'Blog'
      }
    ] as const
  }

  get social () {
    return [
      {
        click: () => analytics.socialLinkedinLink.track(this),
        href: 'https://linkedin.com/company/26254332',
        icon: '$fab-linkedin-in',
        text: 'LinkedIn'
      },
      {
        click: () => analytics.socialTwitterLink.track(this),
        href: 'https://twitter.com/ice_panel',
        icon: '$fab-x',
        text: 'Twitter'
      },
      {
        click: () => analytics.socialMediumLink.track(this),
        href: 'https://icepanel.medium.com',
        icon: '$fab-medium',
        text: 'Medium'
      },
      {
        click: () => analytics.socialDevToLink.track(this),
        href: 'https://dev.to/icepanel',
        icon: '$fab-dev',
        text: 'Dev.to'
      },
      {
        click: () => analytics.socialFacebookLink.track(this),
        href: 'https://facebook.com/icepanel',
        icon: '$fab-facebook-f',
        text: 'Facebook'
      }
    ] as const
  }

  get company () {
    return [
      {
        link: {
          name: 'security'
        },
        text: 'Security'
      },
      {
        href: 'https://docs.google.com/document/d/1lDdXZqBkGL7bKhIl-pSwW6H_Y4CDHMR4/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true',
        text: 'Terms of service'
      },
      {
        href: 'https://docs.google.com/document/d/1dsvVWhD7fVx4Rr7nj9sOWUwLRxVvN0fh/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true',
        text: 'Privacy policy'
      },
      {
        href: 'https://docs.google.com/document/d/1XYLHq_1gF0l1JicXiyLFh2MpgZffS9Xb/edit?usp=sharing&ouid=101483300304989467783&rtpof=true&sd=true',
        text: 'Data processing agreement'
      },
      {
        href: 'https://icepaneltechnologiesinc.gdprlocal.com/eu',
        text: 'Data request'
      },
      {
        link: {
          name: 'careers'
        },
        text: 'Careers'
      }
    ] as const
  }

  get caseStudies () {
    return [
      {
        link: {
          name: 'case-study:alphabet'
        },
        text: 'Alphabet'
      },
      {
        link: {
          name: 'case-study:energy-web'
        },
        text: 'Energy Web'
      },
      {
        link: {
          name: 'case-study:seer-interactive'
        },
        text: 'Seer Interactive'
      }
    ] as const
  }
}
